import React, { useEffect, useState } from 'react';
import { Container, Header, Table, Loader, Button } from 'semantic-ui-react';
import { getRegisteredUsersFor2024 } from '../../app/firestore/firestoreService';

export default function NewRegistrations() {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const registrationsArray = await getRegisteredUsersFor2024();
        setRegistrations(registrationsArray);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching registrations: ", error);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  const exportToCSV = () => {
    const headers = ['Name', 'Initiated Name', 'Email', 'Phone Number', 'Reason for Joining', 'Recommending Devotee'];
    const csvContent = [
      headers.join(','),
      ...registrations.map((reg, index) => [
        index+1,
        `${reg.firstName} ${reg.lastName}`,
        reg.initiatedName,
        reg.email,
        reg.phoneNumber,
        reg.reason,
        reg.recommendingDevotee
      ].map(field => `"${field}"`).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'registrations_2024.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (loading) {
    return <Loader active>Loading Registrations</Loader>;
  }

  return (
    <Container>
      <Header as='h1' textAlign='center' style={{ marginTop: '2em' }}>
        New Registrations for Bhakti-Śāstrī Course 2024
      </Header>
      <Button primary onClick={exportToCSV} style={{ marginBottom: '1em' }}>
        Export to CSV
      </Button>
      <Table celled>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell>S.No.</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Initiated Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Reason for Joining</Table.HeaderCell>
            <Table.HeaderCell>Recommending Devotee</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {registrations.map((registration, index) => (
            <Table.Row key={registration.id}>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>{`${registration.firstName} ${registration.lastName}`}</Table.Cell>
              <Table.Cell>{registration.initiatedName}</Table.Cell>
              <Table.Cell>{registration.email}</Table.Cell>
              <Table.Cell>{registration.phoneNumber}</Table.Cell>
              <Table.Cell>{registration.reason}</Table.Cell>
              <Table.Cell>{registration.recommendingDevotee}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  );
}